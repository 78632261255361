import React from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Table as ChakraTable,
  TableCellProps,
  Tbody,
  Td as ChakraTd,
  Th as ChakraTh,
  Thead,
  Tr,
  Text,
  FlexProps,
  Badge,
} from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { TableSortingState } from './features/Sorting';

import { Skeleton } from '@components/common/Skeleton';

export const Table = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box overflowX='auto'>
      <ChakraTable>{children}</ChakraTable>
    </Box>
  );
};

export const Head = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Thead>{children}</Thead>;
};

export const HeadCell = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <ChakraTh
      borderColor='gray.200'
      color='gray.600'
      textStyle='sm-normal'
      position='relative'
      fontWeight='500'
    >
      {children}
    </ChakraTh>
  );
};

export const SortableHeadCell = ({
  children,
  sortingState,
  isCurrentColumnSorting,
}: {
  children: React.ReactNode;
  sortingState: TableSortingState;
  isCurrentColumnSorting: boolean;
}): JSX.Element => {
  return (
    <HeadCell>
      <Box display='inline-block' position='relative'>
        {children}
        {isCurrentColumnSorting && (
          <Flex
            position='absolute'
            right={'-15px'}
            top='2px'
            direction='column'
          >
            <Icon
              as={sortingState.direction === '+' ? ArrowUpIcon : ArrowDownIcon}
              color='primary.500'
              boxSize={3}
            />
          </Flex>
        )}
      </Box>
    </HeadCell>
  );
};

export const EmptyHeadCell = ({ ...boxProps }: BoxProps): JSX.Element => {
  return (
    <ChakraTh borderColor='gray.200' color='gray.500' textStyle='sm-normal'>
      <Box {...boxProps} />
    </ChakraTh>
  );
};

export const Body = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Tbody>{children}</Tbody>;
};

export const Row = ({
  children,
  onClick,
  isDisabled,
  ...rest
}: {
  onClick?: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}): JSX.Element => {
  return (
    <Tr
      cursor={isDisabled ? 'default' : 'pointer'}
      overflow='hidden'
      onClick={onClick}
      _hover={!isDisabled && { bg: 'gray.50' }}
      {...rest}
    >
      {children}
    </Tr>
  );
};

type TdProps = {
  children: React.ReactNode;
  isLast: boolean;
  isLoading?: boolean;
  wrapperStyleProps?: FlexProps;
  SkeletonComponent?: React.ComponentType;
} & TableCellProps;

export const Cell = ({
  children,
  isLast,
  isLoading,
  wrapperStyleProps,
  SkeletonComponent,
  ...rest
}: TdProps): JSX.Element => {
  const borderStyle = isLast ? { borderColor: 'gray.200' } : { border: 'none' };

  const CellSkeleton = SkeletonComponent || Skeleton;

  return (
    <ChakraTd {...borderStyle} textStyle='sm-normal' color='gray.500' {...rest}>
      <Flex {...wrapperStyleProps}>
        {isLoading ? (
          <CellSkeleton height='13px' width='100%' isLoaded={!isLoading} />
        ) : (
          children
        )}
      </Flex>
    </ChakraTd>
  );
};

export const SkeletonCells = ({
  numberOfCells,
  isLast,
}: {
  numberOfCells: number;
  isLast: boolean;
}): JSX.Element => {
  return (
    <>
      {[...Array(numberOfCells)].map((e, i) => {
        return (
          <Cell
            isLoading
            boxSizing='border-box'
            height={'40px'}
            isLast={isLast}
            key={i}
          >
            Loading
          </Cell>
        );
      })}
    </>
  );
};

export const SkeletonRowCells = ({
  numberOfCells,
  numberOfRows,
}: {
  numberOfCells: number;
  numberOfRows: number;
}): JSX.Element => {
  return (
    <>
      {[...Array(numberOfRows)].map((value, index) => {
        return (
          <Row key={value}>
            <SkeletonCells
              numberOfCells={numberOfCells}
              isLast={index === numberOfRows - 1}
            />
          </Row>
        );
      })}
    </>
  );
};

export const EmptyTable = ({
  message,
  placeholder,
  colSpan = 4,
  isLoading,
}: {
  message: string;
  placeholder?: React.ReactNode;
  colSpan?: number;
  isLoading?: boolean;
}): JSX.Element => {
  if (isLoading) {
    return <SkeletonRowCells numberOfCells={colSpan} numberOfRows={1} />;
  }

  return (
    <>
      <Row>
        <Cell colSpan={colSpan} isLast={true}>
          <Flex width='100%' justifyContent='center'>
            <Text
              as='span'
              textStyle='sm-normal'
              textAlign='center'
              color='blackAlpha.700'
            >
              {message}
            </Text>
          </Flex>
        </Cell>
      </Row>
      {placeholder && (
        <Row isDisabled={true}>
          <Cell colSpan={colSpan} isLast={false}>
            <Skeleton isLoaded={!isLoading}>{placeholder}</Skeleton>
          </Cell>
        </Row>
      )}
    </>
  );
};

export type HeaderProps = {
  title: string;
  badgeMessage?: string;
  description?: string;
  action: JSX.Element;
};

export const TableHeader = ({
  title,
  badgeMessage,
  description,
  action,
}: HeaderProps): JSX.Element => {
  return (
    <Flex pb={5} justifyContent='space-between'>
      <Flex justifyContent='center' gap={2} direction='column'>
        <Box>
          <Text fontSize='lg' fontWeight='600'>
            {title}
            {badgeMessage && (
              <Badge ml={2} size='sm'>
                {badgeMessage}
              </Badge>
            )}
          </Text>
        </Box>

        {description && (
          <Box>
            <Text textStyle='md-normal' color='gray.600'>
              {description}
            </Text>
          </Box>
        )}
      </Flex>
      <Box ml='auto'>{action}</Box>
    </Flex>
  );
};
